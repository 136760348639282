/* You can add global styles to this file, and also import other style files */
@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-alpine.css';
@import '~ng-zorro-antd/ng-zorro-antd.min.css';
@import '~ng-zorro-antd/transfer/style/index.min.css';
/* @import '~ag-grid-community/core/dist/styles/ag-grid.css';
@import '~ag-grid-community/core/dist/styles/ag-theme-alpine.css';
 */

/* or write CSS selectors to make customisations beyond what the parameters support */
.ag-theme-alpine .ag-header-cell-label {
    font-style: italic;
}

/* .ag-header-cell {
    background-color: #000;
    color: #FFF;
} */
/* .ag-header-cell {
    background-color: #000;
    background-color: var(--ag-foreground-color, #000);
    color: #fff;
    color: var(--ag-background-color, #fff);
  } */
.cat {
    color: ag-param(cat-color);
}

/* body, *{
font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif !important;
} */

.btn-blue {
    background-color: #073262 !important;
    border-color: #073262 !important;
}

body,
html {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.btnColr {
    background: #ff4a55 !important;
    border: #ff4a55 !important;
}

.textClr {
    color: #1b2e62 !important;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.ag-theme-alpine .ag-header-cell-label {
    font-style: initial !important;
}


.mat-menu-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.mat-menu-below {
    min-width: 136px !important;
    min-height: 48px !important;
}

.cdk-global-overlay-wrapper {
    justify-content: center !important;
    align-items: center;
}

.mat-radio-label-content {
    font-weight: 100 !important;
    font-size: 15px;
}

.spinner_class {
    position: fixed;
    background: rgba(#000);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    top: 0px;
    left: 0px;
}

.ag-theme-alpine .ag-row {
    height: 30px !important;
}

.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    padding: 0px 6px !important;
} 

.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
    padding: 1px 8px !important;
} 

/*.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    line-height: 20px;
}*/

/* .ag-theme-alpine .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
    height: 0px;
}  */

/* .ag-theme-alpine .ag-checkbox-input-wrapper {
    display: flex;
    align-items: center !important; 
}*/

.ag-theme-alpine .ag-checkbox-input-wrapper input[type="checkbox"],
.ag-theme-alpine .ag-checkbox-input-wrapper input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    opacity: 0;
    margin: 0; 
    padding: 0; 
    vertical-align: middle !important; 
    
}

/* .ag-theme-alpine .ag-checkbox-input-wrapper input[type="checkbox"] + span.ag-checkbox-label {
    margin-left: 5px; 
}


.ag-theme-alpine .ag-checkbox-input-wrapper .ag-checkbox-label {
    font-size: 14px; 
    color: #333; 
    cursor: pointer; 
}  */

div[col-id='AmountPeriod'],
div[col-id='AmountEntered'],
div[col-id='TotBase'],
div[col-id='Trf'],
div[col-id='AmountInML'],
div[col-id='CstAmt'],
div[col-id='Caj'],
div[col-id='Dir'],
div[col-id='Dev'],
div[col-id='BaseGlas'],
div[col-id='Unit'],
div[col-id='AmountInML'],
div[col-id='TotAmt'],
div[col-id='SaleBaseCost'],
div[col-id='InvAmt'],
div[col-id='SalesCostAmt'],
div[col-id='UFAMT'],
div[col-id='AMOUNT_DEDUCTED'],
div[col-id='YEAR_VOLUME'],
div[col-id='MermaGlas'],
div[col-id='DifBase'],
div[col-id='SalesCaseQty'],
div[col-id='SalesUnitQty'],
div[col-id='SalesCostAmt'] {
    text-align: right;
}

div[col-id='VOLUME'],
div[col-id='EARNED'],
div[col-id='AMOUNT_RECEIVED'],
div[col-id='FIXED_AMOUNT_AGREEMENT'],
div[col-id='DEBITED_AMOUNT'],
div[col-id='TOTAL_SALES'],
div[col-id='FIXED_AMT'],
div[col-id='AMT_DEDUCTED'],
div[col-id='OTHER_DEDUCTION'],
div[col-id='AMOUNT_DUE'],
div[col-id='TOTAL_DUE'],
div[col-id='AMOUNT'],
div[col-id='AMT_FOR_THE_PERIOD'],
div[col-id='Jan'],
div[col-id='Feb'],
div[col-id='Mar'],
div[col-id='Apr'],
div[col-id='May'],
div[col-id='TotBase'],
div[col-id='Trf'],
div[col-id='Jun'],
div[col-id='Jul'],
div[col-id='Aug'],
div[col-id='Sep'],
div[col-id='Oct'],
div[col-id='Nov'],
div[col-id='Dec'] {
    text-align: right;
}

/* MX styles */
div[col-id='AMT_DUE'],
div[col-id='POS_SALES_QTY'],
div[col-id='AMS_RATE'],
div[col-id='ALLOW_EARNED'],
div[col-id='Accrual_AMT'],
div[col-id='Adjustments'],
div[col-id='AMS_QTY'],
div[col-id='AMS_SALES_AMT'],
div[col-id='POS_SALES_AMT'],
div[col-id='IVA_DUE'],
div[col-id='IEPS_DUE'],
div[col-id='LOCAL_AMOUNT'],
div[col-id='VNPK_QTY'],
div[col-id='WHPK_QTY'],
div[col-id='VNPK_COST_AMT'],
div[col-id='WHPK_SELL_AMT'],
div[col-id='WEEKLY_STR_COUNT'],
div[col-id='WEEKLY_SALES_AMT'],
div[col-id='WEEKLY_SALES_QTY'],
div[col-id='DAILY_SALES_AMT'],
div[col-id='DAILY_SALES_QTY'],
div[col-id='AVG_SALE_PRICE'],
div[col-id='POS_COST'],
div[col-id='GROSS_AVG_SHIP_COST'],
div[col-id='GROSS_AVG_SHIP_RET'],
div[col-id='GROSS_SHIP_QTY'],
div[col-id='NET_SHIP_QTY'],
div[col-id='STORE_RC_QTY'],
div[col-id='STORE_VC_QTY'],
div[col-id='GROSS_SHIP_COST'],
div[col-id='VNPKS_ORD'],
div[col-id='B_RCVD_QTY'],
div[col-id='E_RCVD_QTY'],
div[col-id='CNT_RCVRS'],
div[col-id='PURCHASES'],
div[col-id='REG_VNPK_CST'],
div[col-id='NET_VNPK_CST'],
div[col-id='UNITS_RCVD'],
div[col-id='VND_PK_QTY'],
div[col-id='WHPK_ORD'],
div[col-id='UNIT_RETAIL'],
div[col-id='TOTAL_COST'],
div[col-id='TOTAL_WHPK_ORD'],
div[col-id='TOTAL_VNPK_ORD'],
div[col-id='VNPK_WGT'],
div[col-id='WGT_RECV'],
div[col-id='SHORT_QTY'],
div[col-id='DAMAGE_QTY'],
div[col-id='WHPK_COST'],
div[col-id='UNIT_COST'],
div[col-id='UNITS_ORD'],
div[col-id='MENGE'],
div[col-id='NETPR'],
div[col-id='KZWI1'],
div[col-id='ACAMT'],
div[col-id='ZZPO_VNDR_PCK_QTY'],
div[col-id='ZZALLOCAMT'],
div[col-id='ZZARESIAMT'],
div[col-id='HDR_NETWR'],
div[col-id='AVG_UNIT_SALES_COST'],
div[col-id='AVG_UNIT_SALES_SELL'],
div[col-id='DAILY_SALES_AMT'],
div[col-id='DAILY_SALES_QTY'],
div[col-id='SHIP_COST'],
div[col-id='SELL_PRICE'],
div[col-id='SHIP_QTY'],
div[col-id='NET_SHIP'],
div[col-id='PURCHASES'],
div[col-id='ENTERED_AMT'],
div[col-id='AMS_PURCHASE'],
div[col-id='PO_PURCHASES'],
div[col-id='AMTDUE'],
div[col-id='IEPS_AMOUNT'],
div[col-id='IVA_AMOUNT'],
div[col-id='TOTAL_DUE'],
div[col-id='OTHER_DEDUCT'],
div[col-id='AMS_QTY'],
div[col-id='SUPERCTR_AMT'],
div[col-id='SUPERAMA_AMT'],
div[col-id='BODEGA_AMT'],
div[col-id='MI_BODEGA_AMT'],
div[col-id='BAE_AMT'],
div[col-id='SAMS_AMT'],
div[col-id='LOCAL_AMOUNT_WITHOUT_TAX'],
div[col-id='USD_AMOUNT'],
div[col-id='QTY_SHORT'],
div[col-id='RETAIL_AMT_SHORT'],
div[col-id='MARGIN_LOST'],
div[col-id='MU_PCT'],
div[col-id='COST_AMT'],
div[col-id='DSD_PURCHASE_AMT'],
div[col-id='INVOICE_COST'],
div[col-id='RETAIL_AMT'],
div[col-id='STORE_SALE_AMT'],
div[col-id='TOTAL_COST_AMT'],
div[col-id='TOTAL_RETAIL_AMT'],
div[col-id='TOTAL_SALE_AMT'],
div[col-id='WHPK_SELL'],
div[col-id='UNIT_COST'],
div[col-id='DEAL_AMOUNT'],
div[col-id='DAILY_SALES_AMT2'],
div[col-id='VNPK_COST_AMT'],
div[col-id='WHPK_SELL_AMT'],
div[col-id='TOTAL_RETAIL_AMT_SHORT'],
div[col-id='TOTAL_MARGIN_LOST'],
div[col-id='TOTAL_EARNED'],
div[col-id='DEDUCTION_CIVA'],
div[col-id='DEDUCTION'],
div[col-id='TOTAL_PENDING'],
div[col-id='AMOUNT_DEDUCTED'],
div[col-id='PENDING_DUE'],
div[col-id='COOP_DEDUCTED'],
div[col-id='AMT_DEDUCTED'],
div[col-id='ALL_BODEGA_POS_QTY'],
div[col-id='ALL_BODEGA_POS_AMT'],
div[col-id='BASE_COOP_AMT'],
div[col-id='BASE_AMT_DEDUCT'],
div[col-id='ADJUSTMENTS'],
div[col-id='BASE_AMT_DUE'],
div[col-id='AMT_DEDUCT_TAXES'],
div[col-id='AMS_PURCHASE'],
div[col-id='PO_PURCHASES'],
div[col-id='OTHER_DED'],
div[col-id='IEPS_AMOUNT'],
div[col-id='IVA_AMOUNT'],
div[col-id='PREVIOUS_CLAIMS'],
div[col-id='IEPS'],
div[col-id='IVA'],
div[col-id='AMTDEDUCTED'],
div[col-id='TOTAL_AMT_PAID'],
div[col-id='TOTAL_SHIPS'],
div[col-id='CASES_RECV'],
div[col-id='HIST_ON_HAND'],
div[col-id='UNITS_SOLD'],
div[col-id='TOTAL_DEFERRED'],
div[col-id='SURCHARGE_SALES'],
div[col-id='OTHER_DEDUCTIONS'],
div[col-id='INV_QTY'],
div[col-id='PACK_QTY'],
div[col-id='QTY_RCVD'],
div[col-id='VDRPK_QTY'],
div[col-id='EQUIV_EACH_INV'],
div[col-id='EQUIVEACHRCVD'],
div[col-id='QTY_SHORT'],
div[col-id='SHORT_DUE'],
div[col-id='IEPS_SHORT_DUE'],
div[col-id='IVA_SHORT_DUE'],
div[col-id='INV_EACH_COST'],
div[col-id='PO_EACH_COST'],
div[col-id='LOW_EACH_COST'],
div[col-id='INV_EXTD_COST'],
div[col-id='WM_PO_COST'],
div[col-id='RCVD_COST'],
div[col-id='COST_PAID'],
div[col-id='SBCOST'],
div[col-id='INV_COST_DUE'],
div[col-id='IEPSCOSTDUE'],
div[col-id='IVA_COST_DUE'],
div[col-id='INV_TOTAL_AMT'],
div[col-id='PO_TOT_AMT'],
div[col-id='RCVD_TOT_AMT'],
div[col-id='TOT_COST_PAID_AMT'],
div[col-id='AP_TOT_AMT'],
div[col-id='INV_UNIT_PRICE'],
div[col-id='VNDRPK_COST'],
div[col-id='ALW_AMT1'],
div[col-id='IEPS_AMT'],
div[col-id='TOT_INV_COST'],
div[col-id='CALC_PO_TOT_COST'],
div[col-id='TOT_RCVD_COST'],
div[col-id='TOT_COST_PAID'] div[col-id='UNIT_COST'],
div[col-id='INV_QTY'],
div[col-id='PACK_QTY'],
div[col-id='NET_COST'],
div[col-id='UNIT_PRICE'],
div[col-id='EXT_COST'],
div[col-id='ORD_QTY'],
div[col-id='ALW_AMT1'],
div[col-id='TTL_PRETAX_INV_AMT'],
div[col-id='TTL_INV_AMT'],
div[col-id='TTL_TAX_AMT'],
div[col-id='INV_TAX'],
div[col-id='SELECT_ROW'],
div[col-id='INV_AMT_AFT_TAX'] {
    text-align: right;
}

.assignTable div[col-id='INCLUDE'],
.assignTable div[col-id='Include'],
div[col-id='VDRSUM'],
/* div[col-id='VDR_SUM'] {
    display: none;
} */

.assignTable div[col-id='SELECT_ROW'],
.assignTable ag-grid-angular ::ng-deep .ag-header-cell[col-id='SELECT_ROW'] {
    display: none !important;
    width: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
}





.datamodule .ag-header-icon.ag-header-cell-menu-button {
    display: none !important;
}

.ag-theme-alpine .ag-ltr .ag-cell {
    border-right: 1px solid #d9dce0 !important;
}

.ag-cell-label-container {
    text-transform: capitalize !important;
}

.ag-theme-alpine .ag-paging-panel {
    height: 32px !important;
}

.pagination {
    margin: 15px 0 !important;
    border-radius: 4px !important;
}

.ag-header.ag-focus-managed.ag-pivot-off {
    min-height: 37px !important;
}

.ag-header-row.ag-header-row-column {
    height: 37px !important;
}

.mat-menu-panel {
    min-height: 41px !important;
}
.txtblue {
    cursor: pointer;
}


